// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('dashboard-outline'),
  cog: icon('cog-outline'),
  home: icon('home-outlined'),
  bug: icon('critical-bug-outlined'),
  policy: icon('outline-policy'),
  endpoints: icon('outline-tune'),
  integrations: icon('tune_white'),
  playbook: icon('library-books-outline'),
  queryStats: icon('query-stats'),
  SIEM: icon('ic_SIEM'),
  manducaIa: icon('ic_manduca-ia'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      /*  {
          title: 'Context',
          path: PATH_DASHBOARD.general.app,
          icon: ICONS.cog,
          children: [
            { title: 'User Management', path: PATH_DASHBOARD.general.app },
            { title: 'My Contexts', path: PATH_DASHBOARD.general.app },
          ],
        },
        { title: 'Home', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.home },
        { title: 'Dashboard', path: PATH_DASHBOARD.general.analytics, icon: ICONS.dashboard }, */

      {
        title: 'Manduca AI',
        path: PATH_DASHBOARD.home.root,
        icon: ICONS.manducaIa,
      },
      {
        title: 'Dashboards',
        path: PATH_DASHBOARD.dashboard.root,
        icon: ICONS.dashboard,
      },
      {
        title: 'Environment',
        path: PATH_DASHBOARD.endpoints.root,
        icon: ICONS.endpoints,
      },
      {
        title: 'Logs',
        path: PATH_DASHBOARD.logs.root,
        icon: ICONS.lock,
      },
      {
        title: 'Alerts',
        path: PATH_DASHBOARD.alerts.root,
        icon: ICONS.bug,
      },
      {
        title: 'Management',
        path: PATH_DASHBOARD.contextManagement.context(),
        icon: ICONS.cog,
      },
      /* Comentados para uso futuro{
        {
        title: 'Audit',
        path: PATH_DASHBOARD.Audit.root,
        icon: ICONS.policy,
      },
      {
        title: 'Vulnerability',
        path: PATH_DASHBOARD.Audit.root,
        icon: ICONS.queryStats,
      },*/
      /*{ title: 'Integrations', path: PATH_DASHBOARD.integrations.root, icon: ICONS.integrations },
      { title: 'Endpoints', path: PATH_DASHBOARD.endpoints.root, icon: ICONS.endpoints },*/
      /* {
        title: 'Playbook',
        path: PATH_DASHBOARD.playbook.root,
        icon: ICONS.playbook,
        children: [{ title: 'Create Playbook', path: PATH_DASHBOARD.playbook.create }],
      },*/
    ],
  },
];

export default navConfig;
