import { useState, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from './Main';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import { Header } from './header/Header';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Memoize NavVertical and NavHorizontal
  const memoizedNavVertical = useMemo(
    () => <NavVertical openNav={open} onCloseNav={handleClose} />,
    [open]
  );

  const memoizedNavHorizontal = useMemo(() => <NavHorizontal />, []);

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
        }}
      >
        {/* Render Nav based on layout */}
        {isNavHorizontal && isDesktop && memoizedNavHorizontal}
        {!isNavHorizontal && (
          <Box
            sx={{
              width: isNavMini ? '0px' : '200px',
              flexShrink: 0,
              transition: 'width 0.3s ease',
              overflow: 'hidden',
              display: isNavMini ? 'none' : 'block',
            }}
          >
            {memoizedNavVertical}
          </Box>
        )}

        {/* Main content always stable - IMPORTANTE  */}
        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
