// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  login: {
    selectLanguage: `Select Language`,
    logoTextMyrmex: `Expert in defense and prevention for cyber atacks`,
    cardTitle: `Sign in to Myrmex`,
    cardSubTitle: `Don't have an account?`,
    cardSubTitleSingUp: `Sign up`,
    dialogTitle: `You are a Root Domain and forgot your password?`,
    dialogContentText: `Enter your email used to login to the platform so that an Ainext employee can contact you to confirm some information to reset your password`,
    inputLabelEmail: `Email Address`,
    inputLabelPassword: `Password`,

    errorEmailRequired: `Email is required`,
    errorEmailValid: `Email must be a valid email address`,
    errorPasswordRequired: 'Password is required',
    errorPasswordWeak: `Password is too weak`,
    errorPasswordMatch: 'Passwords must match',
    NeedHelp: `Need help?`,

    checkboxLabelRemember: `Remember E-mail`,
    linkForgotPassword: `Forgot Password?`,
    buttonLogin: `Login`,
    continueSocial: `Or continue with`,
    buttonGoogle: `Google`,
    buttonMicrosoft: `Microsoft`,
    buttonAWS: `Amazon`,
    started: {
      cardTitleStarted: `Get started to Myrmex.`,
      cardSubTitleAlready: `Already have an account?`,
      cardSubTitleSingIn: `Sign in`,
      cardInputFullName: `Full Name`,
      cardInputCellPhone: `Cell Phone`,
      helmetStarted: `Verify Code`,
      checkEmail: `Please check your email!`,
      checkEmailBody: `We have emailed a 6-digit confirmation code to {{ email }}, please enter the code in below box to verify your email.`,
      dontHaveCode: `Don’t have a code?`,
      linkResendCode: `Resend code`,
      timeResendCode: `Wait for the time to end to resend a new code!`,
      cardCheckEmail: `Check your email inbox`,
      cardVerifyCode: `We've sent a 6-digit confirmation email to your email. Please enter the code in below box to verify your email.`,
      cardVerifyCode2: `If you have not received the email, check your spam or make sure your email address is entered correctly and request the instructions to be resent.`,
      cardInputAlert: `Code is required`,
      cardSnackbarSuceess: `Verify success!`,
      TryAgain: `Try Again`,
      buttonVerify: `Verify`,
      cardEmailRequired: `Email is required`,
      cardFullNameRequired: `Full Name required`,
      cardCellPhoneRequired: `Cell phone required`,
    },
    password: {
      cardTitlePassword: `Password Recovery`,
      cardInputPassword: `Password`,
      cardInputRepeatPassword: `Repeat Password`,
      lowercase: 'One Lowercase letter',
      capital: 'One Uppercase letter',
      number: 'One Number',
      specialChar: 'One Special Character',
      match: 'Passwords match',
      minLength: 'Minimum {{ minLength }} Characters',
      cardResetPassword: `Recover Password`,
      cardTitleRecovePassword: `Recover your password`,
      cardSubTitleRecoverPassword: `Enter your email used to login into the Myrmex platform:`,
      cardEmailRecovery: `email-recovery`,
      cardSnackbarSuceess: `Request Sent Successfully. We will contact you via email!`,
      buttonSend: `Send`,
      resetWeak: `Weak`,
      resetAlmost: `Almost there`,
      resetStrong: `Strong`,
    },
    buttonNext: `Next`,
    buttonReturnSingIn: `Return to sign in`,
    expiredLinkTitle: `Oops, this is an expired link`,
    expiredLinkSubTitle30: `For security reasons, password reset links expire after 30 minutes.`,
    expiredLinkSubTitle24: `For security reasons, password reset links expire after 24 hours.`,
    social: {
      OrContinueWithSignIn: 'Or continue with Sign in',
      completeSingup: {
        AlmostFinished: 'Almost Finished',
        body: 'To ensure your best experience, complete the information below and access the platform:',
      },
      confirmSocialAuth: {
        ConfirmLogin: 'Confirm Login',
        WelcomeBack: 'Welcome Back!',
        body: `There is already an account on the platform linked to this email.
              <br />
              To make your experience even more complete, unify your Myrmexaccount with your {{ TokenProvider }} account.
              <br />
              <br />
              To connect your accounts, simply enter your Myrmex password:`,
        LoginFailure: 'Login Failure.',
        unifyButton: `Use {{ TokenProvider }} to access my Myrmex account`,
      },
    },
  },
  home: {
    link: {
      'device.add': {
        title: `Register Your Device`,
        body: `Any hardware or software that is connected to a network or system and can be used for monitoring`,
        button: `Add Device`,
      },
      'device.view': {
        title: `View Your Devices`,
        body: `Monitor your organization's hardware or software equipment that is connected to the Myrmex platform.`,
        button: `View Devices`,
      },
      'integration.add': {
        title: `Register Your Integration`,
        body: `Connects your applications and allows them to share data. Monitor data from your integrations quickly and securely.`,
        button: `Add Integration`,
      },
      'integration.view': {
        title: `View Your Integration`,
        body: `Applications integrated into the platform allow data sharing. Monitor your integrations quickly and securely.`,
        button: `View Integrations`,
      },
      'sub-org.add': {
        title: `Register Your Sub Organization`,
        body: `In the Myrmex system refers to the corporate or institutional structure of a client.`,
        button: `Add Sub Organization`,
      },
      'sub-org.view': {
        title: `View Yours Sub Organizations`,
        body: `In the Myrmex system refers to the corporate or institutional structure of a client. `,
        button: `View Sub Organizations`,
      },
      'context.add': {
        title: `Register Your Context`,
        body: `Is a logical structure that represents the segmentation and organization of data.`,
        button: `Add Context`,
      },
      'context.view': {
        title: `View Yours Contexts`,
        body: `Is a logical structure that represents the segmentation and organization of data.`,
        button: `View Contexts`,
      },
      'team.add': {
        title: 'Invite your Team',
        body: 'Be up to date on team activities and task assignments',
        button: 'Add User',
      },
    },
    totals: {
      devices: `Total Devices`,
      integrations: `Total Integrations`,
      alerts: `Total Alerts`,
      LastPeriod: `Last {{period}}`,
    },
    invite: {
      Organization: 'Organization',
      UserGroup: 'User Group',
      Cancel: 'Cancel',
      Send: 'Send',
      Admin: 'Admin',
      User: 'User',
      OrganizationRequired: 'Organization is required',
      TeamInvited: 'Team invited!',
      TeamInviteFailed: 'Failed to invite user.',
    },
    invitation: {
      title: 'You are invited to join the organization: {{name}}',
      body_variation_1: `By accepting, you will become a member of this organization, gaining visibility into its environment.
      Please be aware that by doing so, you will lose access to your current organization.`,
      body_variation_2: `{{name}} invited you, by accepting you become a member of this organization and
      gain visibility into its environment. With this you will lose access to your current organization.`,
      radioAccept: 'I accept to be part of the organization {{name}}.',
      radioDecline: 'Refuse invite.',
      buttonDecline: 'Decline',
      buttonJoin: 'Join the Organization',
      InvitationAnswered: 'Invitation answered!',
      InvitationAnswerFailed: 'Failed to answer invitation.',
    },
  },
  loadingAlert: 'Please wait, <br> we are loading the alert...',
  preparingEnvironment: `Preparing your environment...`,
  noContextSelectedTitle: 'No results found',
  noContextSelectedSubTitle: 'Please check if the desired context is selected',
  Alerts: 'Alerts',
  Home: 'Home',
  Dashboard: 'Dashboard',
  NoDataAvailable: 'No data available. Please verify that the desired context is selected.',
  Endpoints: 'Endpoints',
  Integrations: 'Integrations',
  integrations: {
    new: 'New Integration',
    authorization: {
      authorizedSnack: 'Authorization Completed!',
      unauthorizedSnack: 'Unauthorization Completed!',
      authorized: 'Authorized',
      unauthorized: 'Unauthorized',
    },
    vendorFilterPlaceholder: 'Vendors',
    searchFilterPlaceholder: 'Search...',
    clearFilters: 'Clear filters',
    addIntegrationsButton: 'Add Integration',
    connect: 'Connect',
    registration: {
      title: 'Integration Registration',
      prerequisites: 'Prerequisites',
      instructions_one: 'Fill in the device details below to start the integration:',
      contexts_description: 'This integration will be associated with the selected context.',
      insert_placeholder: 'Fill here',
      integration_name: 'Name',
      integration_description: 'Description',
      integration_user: 'User',
      integration_SNMP_description:
        'Myrmex uses SNMP version 3 by default and the default port is 161.',
      integration_SNMP_password: 'SNMP Password',
    },
  },
  Logs: 'Logs',
  Management: 'Management',
  Contexts: 'Contexts',
  header: {
    'context.SelectContext': 'Select Context',
  },
  AlertsPage: {
    title: 'Alerts',
    details: {
      title: 'Alert Details',
    },
  },
  'Email is required': 'Email is required',
  'Email must be a valid email address': 'Email must be a valid email address',
  'Organization is required': 'Organization is required',
  'Group is required': 'Group is required',
  period: 'period',
  year: 'year',
  year_other: 'years',
  month: 'month',
  month_other: 'months',
  week: 'week',
  week_other: 'weeks',
  day: 'day',
  day_other: 'days',
  hour: 'hour',
  hour_other: 'hours',
  'user.profile': {
    link: 'My Profile',
    title: 'User Account',
    OldPassword: 'Old Password',
    NewPassword: 'New Password',
    ConfirmNewPassword: 'Confirm New Password',
    allowedFiles: 'Allowed {{files}}',
    maxSize: 'Max size of {{maxSize}}',
    userProfile: 'User Profile',
    ChangePassword: 'Change Password',
    Authentication: 'Authentication',
    Back: 'Back',
    SaveChanges: 'Save Changes',
    Organization: 'Organization',
    Name: 'Name',
    Phone: 'Phone',
    Email: 'Email',
    updatePhoto: 'Update photo',
    uploadPhotoError: 'Error uploading photo',
    userSaveError: 'Error saving user profile',
    userSaveSuccess: 'User profile updated',
    Acknowledged: 'Acknowledged',
    'Closed Alerts': 'Closed Alerts',
    logoutError: 'Unable to logout!',
    linkSuccess: 'Account successfully linked!',
    SocialLogin: 'Social Login',
    socialLoginInstructions:
      'Make your access easier: Link your Myrmex account using one of the options below. <br /> Make sure the email registered on the platform is the same as the one used for your login social',
    confirmPassword: 'To connect your account to the platform, confirm with your Myrmex password:',
    useProvider: 'Use {{ useProvider }} to access my Myrmex account',
    remove: 'Remove',
    disconnect:
      'To unlink your account from the platform, click remove, you will be redirected to reset your password.',
  },
  management: {
    breadcrumbs: 'Management',
    alert: {
      success: 'Change saved successfully',
      error: 'Error saving change',
      contractSuccess: 'Contract canceled successfully',
      contractCancel: 'Error cancelling contract',
      errordays: 'Contract must be at least 30 days',
    },
    invalidDate: 'Invalid Date',
    insufficientPointsTitle: 'Insufficient Points',
    insufficientPointsMessage:
      'You do not have enough points to purchase this package. Please check your available points balance or acquire more points to proceed with the purchase.',
    confirmCancelTitle: 'Confirm Contract Cancellation',
    confirmCancelMessage:
      'Do you want to confirm the cancellation of this contract? It will remain active until {{endDate}}. After this date, the contract will expire, and all authorized devices will be automatically deauthorized.',
    transactionNotCompletedTitle: 'Transaction Not Completed',
    transactionNotCompletedMessage: 'The transaction was not successful. Please contact support.',
    contractDetails: 'Contract Details',
    authorizationTitle: 'Authorization for Monthly Contract Creation',
    period: 'Period: {{startDate}} to {{endDate}}',
    devices: 'Devices: {{devices}}',
    integration: 'Integration: {{integration}}',
    xdr: 'XDR: {{xdr}}',
    mdr: 'MDR: {{mdr}}',
    storage: 'Storage: {{storage}}',
    transactionPoints: 'This transaction will use {{points}} points. Do you authorize it?',
    input: {
      country: 'Country',
      cnpj: 'CNPJ',
      companyTradeName: 'Company Trade Name',
      companyName: 'Company Name',
      startDate: 'Start Date',
      endDate: 'Expiration Date',
      device: 'Device',
      integration: 'Integration',
      xdr: 'XDR',
      mdr: 'MDR',
      storage: 'Storage',
      points: 'Points',
      actions: 'Actions',
    },
    button: {
      save: 'Save',
      cancel: 'Cancel',
      yes: 'Yes',
      create: 'Create',
      close: 'Close',
      ok: 'Ok',
      send: 'Send',
      confirm: 'Confirm',
      newContract: 'New contract',
      confirmCancel: 'Confirm Contract Cancellation',
      cancelContract: 'Cancel Contract',
    },
    dialog: {
      transactionNotCompleted: 'Transaction Not Completed',
      transactionError: 'The transaction was not successful. Please contact support.',
    },
    popover: {
      insufficientPointsTitle: 'Insufficient Points',
      insufficientPointsMessage:
        'You do not have enough points to purchase this package. Please check your available points balance or acquire more points to proceed with the purchase.',
    },
    checkbox: {
      allowSubOrganization: "Allow the sub organization to consume this organization's points.",
    },
    popUpAllowSubOrganization: {
      title: 'Allow the sub organization to consume {{ organizationName }} points',
      description:
        'For example, if any of the sub-organizations managed by {{organizationName}} gets zero points. The points from {{organizationName}} will be used. Confirm this action?',
    },
    points: {
      title: 'Points',
      subTitleAvailable: 'Available',
      subTitleConsumed: 'Consumed',
      descriptionDay: 'Day',
      descriptionWeek: 'Week',
      descriptionMonth: 'Month',
    },
    package: {
      title: 'Packages',
      subTitleDevice: 'Device',
      subTitleIntegration: 'Integration',
      subTitleMDR: 'MDR',
      subTitleXDR: 'XDR',
    },
    diskAvailable: {
      title: 'Disk Available',
      description: 'used from ',
      subtitle: 'Using',
    },
    tabContexts: 'Contexts',
    tabSubOrganizations: 'Sub Organizations',
    contexts: {
      placeholder: 'Search',
      button: 'New Context',
      name: 'Name',
      organization: 'Organization',
      totalDevice: 'Total Devices',
      totalIntegration: 'Total Integrations',
      actions: 'Actions',
    },
    subOrganization: {
      placeholder: 'Search',
      button: 'New Sub Organization',
      name: 'Name',
      contracts: 'Contracts',
      points: 'Points',
      contexts: 'Contexts',
      device: 'Device',
      integration: 'Integration',
      mdr: 'MDR',
      storage: 'Storage',
      modal: {
        title: 'New Sub Organization',
        description:
          "In the Myrmex system, it refers to a client's corporate or institutional structure managed by the ",
        placeholderCountry: 'Country',
        placeholderCnpj: 'CNPJ',
        placeholderCompanyName: 'Company Name',
        placeholderTradeName: 'Company Trade Name',
      },
    },
    users: {
      title: 'Users',
      columnsUser: 'User',
      columnsName: 'Full Name',
      columnsGroups: 'Groups',
      columnsVerified: 'Verified',
      columnsAction: 'Remove User',
      button: 'Invite User',
      invite: {
        title: 'Invite User',
        email: 'Email Address',
        emailRequired: 'Email is required',
        emailError: 'Invalid email address',
        group: 'Group',
        groupRequired: 'Group selection is required',
        group1: 'Group Full Access Organization',
        group2: 'Group Read Only Organization',
        success: 'Invite send successfully',
        error: 'Error Invite send',
        invitation: 'Invitation sent',
        employee:
          'The Employee will receive an invitation to the organization {{organizationName}} in his email.',
        employeeAccess:
          'To access myrmex, the employee needs to validate the email confirmation token.',
        alertUpdatePolicy: 'Policy updated successfully',
        alertWarningPolicy: 'No matching policy found',
        alertErrorPolicy: 'Failed to update policy',
        fullAccess: 'Full Access',
        readOnly: 'Read Only',
      },
      remove: {
        title: 'Remove User',
        subtitle: 'Are you sure you want to remove this user from the organization ',
        description: ' will lose all access to the sub organization and contexts.',
      },
    },
  },
  endpoints: {
    Authorization: 'Authorization',
    AllEndpoints: 'All Endpoints',
    Authorized: 'Authorized',
    Unauthorized: 'Unauthorized',
    MonitoringAction: {
      Monitoring: 'Monitoring',
      Description: 'Would you like to monitor this services status?',
      NumberOfTests: 'Number of tests',
      TestsRange: 'Between 1 and 10',
      Cancel: 'Cancel',
      Save: 'Save',
      Start: 'Start',
      Restart: 'Restart',
      Stop: 'Stop',
    },
    deviceIsAServer: 'This device is a server',
  },
  alert: {
    status: {
      Status: 'Status',
      open: 'Open',
      acknowledged: 'Acknowledged',
      closed: 'Closed',
    },
    crititicality: {
      Critical: 'Critical',
      High: 'High',
      Medium: 'Medium',
      Low: 'Low',
      Information: 'Information',
    },
  },
  logs: {
    logsDate: 'Date',
    logsActions: 'Actions',
    copiedText: 'Copied text!',
    downloadCompleted: 'Download Completed!',
    logsVolumetry: 'Logs Volumetry',
    logsTheLast24: 'In the last 24 hours',
    search: 'Search',
    refresh: 'Refresh',
    downloadCSV: 'Download CSV',
    examplePlaceholder:
      'Example... collector_os:windows&event_eventdata_destport:10050&Vendor:Fortinet',
    today: 'Today',
    last15Minutes: 'Last 15 minutes',
    last30Minutes: 'Last 30 minutes',
    last1Hour: 'Last 1 hour',
    last24Hours: 'Last 24 hours',
    last7Days: 'Last 7 days',
    last30Days: 'Last 30 days',
    last90Days: 'Last 90 days',
    last1Year: 'Last 1 year',
    expandedDocument: 'Expanded document',
    goTo: 'Go to',
    table: 'Table',
    json: 'JSON',
    field: 'Field',
    value: 'Value',
    action: 'Action',
    addFieldToFilter: 'Add field to filter in search',
    copyCode: 'Copy Code',
    noDate: 'Sem data cadastrada',
    availableFields: 'Available Fields',
    status: 'Status',
    lookBackTime: 'Look-back-Time',
  },
  months: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
  },
  chat: {
    title: 'Cybersecurity Assistant',
    newChat: 'New Chat',
    copy: 'Copy',
    inputPlaceholder: 'How can MYRMEX help you today?',
    sendButton: 'Send',
    charLimitError: 'It is not allowed to type more than 500 characters.',
    errorMessage: 'Something went wrong. Please wait a moment and try sending your message again.',
    loadingMessage: 'Typing',
    helloMessage: 'Hello, {{userName}}!',
    helpMessage: 'How can I help you?',
    loadMoreMessages: 'Load more messages',
    shiftMessage: 'Use shift + return for new line',
    noContextSelectedError: 'Select a context to start a conversation.',
    downloadInstallApp: 'How to download and install App',
    appWorksOnComputer: 'How the App works on the computer',
    appProtectMe: 'How can the App protect me',
  },
};

export default en;
