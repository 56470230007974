// routes
import { PATH_DASHBOARD } from './routes/paths';

export const DEV_MODE = process.env.NODE_ENV === 'development';
export const GIT_COMMIT_SHA = process.env.REACT_APP_GIT_COMMIT_SHA;

function maybeNumber(s?: string) {
  if (!s) return undefined;
  try {
    return Number.parseInt(s);
  } catch (e) {}
  return undefined;
}
/**
 * @example 126
 * @example 'test'
 */
export const VERSION = maybeNumber(process.env.REACT_APP_VERSION) || process.env.NODE_ENV;
export const FEATURES = {
  CORRELATION_ID: process.env.REACT_APP_USE_CORRELATION_ID === 'true',
  AUTH_PROVIDERS: process.env.REACT_APP_FEATURE_AUTH_PROVIDERS === 'true',
  DARK_MODE: process.env.REACT_APP_FEATURE_DARK_MODE !== 'false',
};

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.home.root; // as '/dashboard/app'

export const GOOGLE_API_CLIENT_ID = process.env.REACT_APP_GOOGLE_API_CLIENT_ID || '';
export const MICROSOFT_API_CLIENT_ID = process.env.REACT_APP_MICROSOFT_API_CLIENT_ID || '';

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 54,
  H_MAIN_DESKTOP: 44,
  H_DASHBOARD_DESKTOP: 60, // Espaço necessário pro componente por enqanto, mas talvez dê pra diminuir o tamanho dele
  H_DASHBOARD_DESKTOP_OFFSET: 60 - 12,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 200,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
