import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Checkbox,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
} from '@mui/material';

import { useJwtAuthContext } from '../../../auth/useAuthContext';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import MenuPopover from '../../../components/menu-popover';
import { useContextProvider } from '../../../context/useContextProvider';
import { useLocales } from '../../../locales';
import AgentFilterButton from '../../../sections/integrations/agent/filter/AgentFilterButton';
import { ContextsItem } from '../../../context/ContextApi';

interface ContextSelectItem {
  id: number;
  name: string;
  isSelected: boolean;
  context: ContextsItem;
}

interface ContextPopoverProps {
  disableSelectContext?: boolean;
}

function PopoverContent() {
  const { currentLang } = useLocales();
  const { user, getUser } = useJwtAuthContext();
  const { selectedContexts, setSelectedContexts } = useContextProvider();

  useEffect(() => {
    if (user?.userContexts?.length === undefined) {
      getUser();
    } else if (user?.userContexts?.length > 0 && selectedContexts?.length === 0) {
      // Select the first context if none are selected
      setSelectedContexts([user.userContexts[0]]);
    }
  }, [getUser, user, selectedContexts, setSelectedContexts]);

  const contextList: ContextSelectItem[] = useMemo(
    () =>
      user?.userContexts
        ?.filter(
          // remove context with ID 1 if there are other contexts
          (context, _, self) => context.id !== 1 || self.length === 1
        )
        .map<ContextSelectItem>((context) => ({
          ...context,
          context,
          isSelected: selectedContexts?.some((item) => item.name === context.name) ?? false,
        }))
        .sort((a, b) =>
          a.name
            .trim()
            .toLocaleUpperCase(currentLang.value)
            .localeCompare(b.name.trim().toLocaleUpperCase(currentLang.value), currentLang.value)
        ) ?? [],
    [user?.userContexts, selectedContexts, currentLang]
  );

  // assumes previous selections were already in sync with slectedContexts
  const onFilterType = useCallback(
    (selection: ContextSelectItem) => {
      // Only allow one context to be selected at a time and prevent deselecting all contexts
      const newSelectedContexts = selectedContexts?.some((item) => item.id === selection.id)
        ? selectedContexts // Keep the current selection if the same context is clicked
        : [selection.context]; // Select the clicked context

      setSelectedContexts(newSelectedContexts);
    },
    [setSelectedContexts, selectedContexts]
  );

  return (
    <Stack spacing={2.5}>
      <Box display="block" gap={1}>
        {contextList.length === 0 && (
          <ListItemButton role={undefined} dense disabled>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={false}
                disableRipple
                disabled
                inputProps={{ 'aria-labelledby': 'loading-context-list' }}
              />
            </ListItemIcon>

            <ListItemText
              id="loading-context-list"
              primary={<Skeleton variant="rounded" width={260} height={22} />}
            />
          </ListItemButton>
        )}
        {contextList.map((type) => {
          const labelId = `checkbox-list-label-${type.id}`;
          return (
            <ListItemButton key={type.id} role={undefined} dense onClick={() => onFilterType(type)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={type.isSelected}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>

              <ListItemText id={labelId} primary={type.name} />
            </ListItemButton>
          );
        })}
      </Box>
    </Stack>
  );
}

export function ContextPopover({ disableSelectContext = false }: ContextPopoverProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const { selectedContexts } = useContextProvider();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  let label = (
    <>
      {selectedContexts?.[0]?.name ?? (
        <Trans i18nKey="header.context.SelectContext">Select Context</Trans>
      )}
    </>
  );
  if (
    selectedContexts?.length !== undefined &&
    selectedContexts.length > 0 &&
    selectedContexts[0]?.name !== undefined
  ) {
    label = <>{selectedContexts[0].name}</>;
    if (selectedContexts.length > 1) {
      label = (
        <>
          {selectedContexts[0].name}
          <Label color="info" sx={{ ml: 1, textTransform: 'none' }}>
            +{selectedContexts.length - 1}
          </Label>
        </>
      );
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        maxWidth: 300,
        borderRadius: '10px',
        border: `solid 1px ${theme.palette.divider}`,
        borderColor: 'transparent',
        bgcolor: isDarkMode
          ? theme.palette.background.default
          : theme.palette.newBackground.default,
        backgroundColor: isDarkMode
          ? theme.palette.background.default
          : theme.palette.newBackground.default,
        boxShadow: theme.customShadows.z20,
      }}
    >
      <AgentFilterButton
        isSelected={!!selectedContexts?.length}
        endIcon={<Iconify icon={openPopover ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
        onClick={handleOpenPopover}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          textTransform: 'none',
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.newBackground.default,
        }}
        disabled={disableSelectContext}
      >
        {label}
      </AgentFilterButton>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ p: 2, overflowY: 'scroll' }}
      >
        <PopoverContent />
      </MenuPopover>
    </Box>
  );
}
