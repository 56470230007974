import { Navigate, useRoutes } from 'react-router-dom';
import { AlertsContextProvider } from 'src/pages/alerts/AlertsContext';
import AuthGuard from '../auth/AuthGuard';
import FistAccessExclusiveGuard from '../auth/FirstAccessExclusiveGuard';
import GuestGuard from '../auth/GuestGuard';
import { useJwtAuthContext } from '../auth/useAuthContext';
import { PATH_AFTER_LOGIN } from '../config-global';
import { ContextProviderMethods } from '../context/ContextProvider';
import { CompactLayout } from '../layouts/compact/CompactLayout';
import DashboardLayout from '../layouts/dashboard';
import { LoginLayoutCenter } from '../layouts/login/LoginLayoutCenter';
import { LoginTheme } from '../pages/auth/LoginTheme';
import { DashboardProvider } from '../pages/dashboard/DashboardContext';
import { IntegrationsProvider } from '../pages/integrations/IntegrationsContext';
import { EndpointsProvider } from 'src/pages/endpoints/EndpointsContext';
import { LogProvider } from '../pages/logs/LogsContext';
import { PlaybooksProvider } from '../pages/playbook/PlaybookContext';
import { UsersContextProvider } from '../pages/user/UsersContext';
import {
  AlertDetailsPage,
  AlertsListpage,
  CompleteSingupPage,
  ConfirmSocialAuthPage,
  ContextManagementPage,
  DashboardPageLazy,
  DashboardPageDynamic,
  DomainManagementPage,
  // Dashboard: General
  EndpointsPage,
  EndpointsDetailsPage,
  FeatureExplorer,
  FirstAccessPage,
  HomePageLazy,
  IntegrationsPage,
  // Auth
  LoginPage,
  LogsPage,
  NewPasswordPage,
  Page403,
  Page404,
  Page500,
  PlaybookBuildPage,
  PlaybookPage,
  RegisterPage,
  ResetPasswordPage,
  UserProfilePage,
  VerifyCodePage,
  VulnerabilitiesPage,
  AlertDetailsPageDynamic,
} from './elements';
import { PATH_DASHBOARD } from './paths';

export default function Router() {
  const { user } = useJwtAuthContext();
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          element: (
            <LoginTheme>
              <LoginLayoutCenter />
            </LoginTheme>
          ),
          children: [
            {
              path: 'login',
              element: (
                <GuestGuard>
                  <LoginPage />
                </GuestGuard>
              ),
              index: true,
            },
            {
              path: 'register',
              element: (
                <GuestGuard>
                  <RegisterPage />
                </GuestGuard>
              ),
            },
            { path: 'login-unprotected', element: <LoginPage /> },
            { path: 'register-unprotected', element: <RegisterPage /> },
            { path: 'confirm-social-auth', element: <ConfirmSocialAuthPage /> },
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
            { path: 'complete-singup', element: <CompleteSingupPage /> },
          ],
        },
        { path: 'feature-explorer', element: <FeatureExplorer /> },
      ],
    },

    // Dashboard
    {
      path: 'console',
      element: (
        <AuthGuard>
          <UsersContextProvider>
            <ContextProviderMethods>
              {/* <FirstAccessGuard> */}
              <DashboardLayout />
              {/* </FirstAccessGuard> */}
            </ContextProviderMethods>
          </UsersContextProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'home',
          element: <HomePageLazy />,
        },
        {
          path: 'dashboard',
          element: (
            <PlaybooksProvider>
              <IntegrationsProvider>
                <DashboardProvider>
                  <DashboardPageLazy />
                </DashboardProvider>
              </IntegrationsProvider>
            </PlaybooksProvider>
          ),
        },
        {
          path: 'dashboard/dynamic',
          element: (
            <PlaybooksProvider>
              <IntegrationsProvider>
                <DashboardProvider>
                  <DashboardPageDynamic />
                </DashboardProvider>
              </IntegrationsProvider>
            </PlaybooksProvider>
          ),
        },
        {
          path: 'endpoints',
          element: (
            <IntegrationsProvider>
              <EndpointsProvider>
                <EndpointsPage />
              </EndpointsProvider>
            </IntegrationsProvider>
          ),
        },
        {
          path: 'endpoints/:contextId/:endpointId',
          element: (
            <IntegrationsProvider>
              <EndpointsProvider>
                <EndpointsDetailsPage />
              </EndpointsProvider>
            </IntegrationsProvider>
          ),
        },
        {
          path: 'integrations',
          element: (
            <IntegrationsProvider>
              <EndpointsProvider>
                <IntegrationsPage />
              </EndpointsProvider>
            </IntegrationsProvider>
          ),
        },
        {
          path: 'logs',
          element: (
            <LogProvider>
              <LogsPage />
            </LogProvider>
          ),
        },
        {
          path: 'vulnerabilities',
          element: (
            <IntegrationsProvider>
              <VulnerabilitiesPage />
            </IntegrationsProvider>
          ),
        },
        {
          path: 'playbook',
          element: (
            <PlaybooksProvider>
              <PlaybookPage />
            </PlaybooksProvider>
          ),
        },
        {
          path: 'playbook/create',
          element: (
            <PlaybooksProvider>
              <PlaybookBuildPage />
            </PlaybooksProvider>
          ),
        },
        {
          path: 'alerts/',
          element: (
            <LogProvider>
              <AlertsContextProvider />
            </LogProvider>
          ),
          children: [
            { path: '', element: <AlertsListpage /> },
            { path: ':contextId/:alertId', element: <AlertDetailsPage /> },
            { path: 'dynamic/:contextId/:alertId', element: <AlertDetailsPageDynamic /> },
          ],
        },
        {
          path: 'management/context/',
          element: (
            <Navigate
              to={PATH_DASHBOARD.contextManagement.context(user?.organization.id)}
              replace
            />
          ),
        },
        {
          path: 'management/context/:organizationId',
          element: (
            <IntegrationsProvider>
              <ContextManagementPage />
            </IntegrationsProvider>
          ),
        },
        {
          path: 'management/domain',
          element: (
            <IntegrationsProvider>
              <DomainManagementPage />
            </IntegrationsProvider>
          ),
        },
      ],
    },

    // User
    {
      path: 'user',
      element: (
        <AuthGuard>
          <UsersContextProvider>
            <ContextProviderMethods>
              <DashboardLayout />
            </ContextProviderMethods>
          </UsersContextProvider>
        </AuthGuard>
      ),
      children: [
        { path: 'profile', element: <UserProfilePage /> },
        { path: 'profile/:userId', element: <UserProfilePage /> },
      ],
    },

    // ROOT
    {
      path: '/',
      children: [
        {
          path: '/',
          element: <Navigate to="/auth/login" replace />,
        },
        {
          element: <CompactLayout />,
          children: [
            { path: '403', element: <Page403 /> },
            { path: '404', element: <Page404 /> },
            { path: '500', element: <Page500 /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <Page404 />,
    },

    {
      path: '/FirstAccess',
      element: (
        <AuthGuard>
          <FistAccessExclusiveGuard>
            <FirstAccessPage />
          </FistAccessExclusiveGuard>
        </AuthGuard>
      ),
    },
  ]);
}
