import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, BoxProps, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  horizontal?: boolean;
  width?: number;
  height?: number;
}

export const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, horizontal = false, width = 59, height = 42, sx, ...other }, ref) => {
    const theme = useTheme();

    const { src, logoWidth, logoHeight } = horizontal
      ? {
          src: '/logo/logo_full_dark.svg',
          logoWidth: 410,
          logoHeight: 142,
        }
      : {
          src:
            theme.palette.mode === 'light'
              ? '/logo/myrmex_logo_light.svg'
              : '/logo/logo_full_dark.svg',
          logoWidth: 100,
          logoHeight: 32,
        };

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 1,
            ...sx,
          }}
        >
          <Box
            component="img"
            src={src}
            sx={{ width: logoWidth, height: logoHeight, cursor: 'pointer' }}
          />
        </Box>
      </Link>
    );
  }
);

export default Logo;
